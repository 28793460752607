import React from 'react';
import './styles.css';
import headshot from './Headshot.png';
import Navbar from '../Components/Navbar'
import { useMediaQuery } from 'react-responsive';
import { SocialIcon } from 'react-social-icons';

function HomePage() {
const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
    <Navbar />
    <div className='outerContainer' >
        <div className='outerContainer2' >
        <div className='left'> 
            <div className='nameText'> 
                Mehul Rastogi
                <span className="dotBig">.</span>
                </div>
            <div className='smallText'> 
            Hey 👋 Welcome to my corner of the Internet! <br />
I'm extremely passionate about engineering and startups. I'm currently at Georgia Tech, getting my BS & MS in Computer Science. I love <a href="/projects">hacking on projects</a> and building cool things 🚀<br />

Previously, I worked on engineering/product at <a href="https://www.coinbase.com/" target="blank">Coinbase</a>, <a href="https://mercury.com/" target="blank">Mercury</a>, <a href="https://avenue.app/" target="blank">Avenue</a>, <a href="https://www.bizjournals.com/atlanta/news/2016/08/23/ncr-adding-innovation-lab-to-atlanta-headquarters.html" target="blank">NCR's Innovation Lab</a>, and <a href="https://www.untapped.ventures/" target="blank">Untapped</a> 👷<br />

I'm passionate about Fintech, Crypto, B2B SaaS, Logistics, and AI/ML. Ages ago, I hosted a podcast - <a href="https://open.spotify.com/show/0ZVOPMPCqXx0lMVqHX6dPs" target="blank">Capercast</a> 🎙️<br />

Outside of tech, I enjoy traveling, driving, and <a href="http://food.mehulrastogi.com" target="blank">eating out</a>. Recently, I've been diving exploring scuba diving 🤿 <br />
            </div>
        </div>
        {!isMobile && (<div >
        <img src={headshot} alt="Mehul Rastogi Headshot" />
        </div>)}
    </div>    
    <div className='icons'> 
    <SocialIcon bgColor = 'beige' fgColor='#007bff' className='socialIcons' url="https://twitter.com/mehulrastogi20" target='blank'/>
    <SocialIcon bgColor = 'beige' fgColor='#007bff' className='socialIcons' url="https://www.linkedin.com/in/mehul-rastogi-2002/" target='blank' />
    <SocialIcon bgColor = 'beige' fgColor='#007bff' className='socialIcons' url="https://github.com/Mehul20" target='blank'/>
    <SocialIcon bgColor = 'beige' fgColor='#007bff' className='socialIcons' url="https://www.instagram.com/mehul_20/" target='blank'/>
    </div>
    </div> 
    </>
  );
}

export default HomePage;
