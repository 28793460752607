import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import '../../styles/card.css'

function Curate () {
return (
<Grid item xs={12} sm={6} md={4} className="grid">
            <Card className="card">
              <CardActionArea>
                <CardMedia
                  sx = {{height:"200px"}}
                  component="img"
                  image =  "https://mehulrastogi.com/palette-landing.png"
                  title = "Social List Curation Platform"
                />
                <CardContent>
                  <div className='typo' >
                    Palette
                  </div>
                  <div className='typo2'> 
                  A social platform to curate and share all your interests in one place. Currently Suppoting Books, TV Shows, Movies, Web Sites, Blogs, and Video Games. Sign up and connect with your friends!
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          );
}

export default Curate;
